var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        ref: "file",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: _vm.accept },
        on: { change: _vm.onFileChange }
      }),
      _c(
        "inqli-button",
        { attrs: { width: _vm.width }, on: { click: _vm.upload } },
        [_vm._v(_vm._s(_vm.buttonTitle))]
      ),
      _c("div", { staticClass: "error-msg" }, [
        _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
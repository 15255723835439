import { Country, State, City } from "country-state-city";

/**
 * @returns A list of countries with attributes: text (display name), value (country code)
 */
export const getCountryList = () => {
  const countries = Country.getAllCountries();
  return countries.map((country) => ({
    text: country.name,
    value: country.isoCode,
  }));
};

/**
 * @param country country iso code
 * @returns a list of states in given country with attributes:
 * - text (display name)
 * - value (state code)
 * @note if country is not given, it will return empty array
 */
export const getStateListByCountry = (country) => {
  if (!country) {
    return [];
  }
  const stateNames = State.getStatesOfCountry(country);
  const states = stateNames.map((state) => ({
    text: state.name,
    value: state.isoCode,
  }));
  return states;
};

/**
 *
 * @param state state iso code
 * @param country country iso code
 * @returns a list of city name in given state in given country
 * @note if state or country is not given, it will return empty array
 */
export const getCityListByStateCountry = (state, country) => {
  if (!state && !country) {
    return [];
  }
  const cityNames = City.getCitiesOfState(country, state);
  const cities = cityNames.map((city) => city.name) || [];
  return cities;
};

import sendRequest from "./send-request-helper";

const getFileExtension = (file) => {
  return file.name.split(".").pop();
};

/**
 *
 * @param {*} file
 * @param {*} originFile attribute fileName, resourceType, contentType
 * @returns
 */
const uploadFileHelper = async (file, originFile) => {
  // check if value is a file
  if (!(file instanceof File)) {
    throw new Error("Value is not a file");
  }
  // get file object info from server
  let preSignInfo = await sendRequest({
    url: `${
      process.env.VUE_APP_URL
    }/services/images?file-extension=${getFileExtension(file)}`,
    method: "GET",
    isAuth: true,
  });
  // upload file to s3 bucket
  const formData = new FormData();
  Object.keys(preSignInfo.fields).forEach((key) => {
    formData.append(key, preSignInfo.fields[key]);
  });
  formData.append("file", file);
  await fetch(preSignInfo.url, {
    method: "POST",
    body: formData,
    mode: "cors",
  });
  // delete original file from server (it can be async)
  originFile && deleteImage(originFile);
  // return image url
  return preSignInfo.url + preSignInfo.fields.key;
};

export const deleteImage = async ({ fileUrl, resourceType, resourceId }) => {
  const fileName = fileUrl.split("/").pop();
  await sendRequest({
    url: `${process.env.VUE_APP_URL}/services/images/${fileName}?resource_id=${resourceId}&resource_type=${resourceType}`,
    method: "DELETE",
    isAuth: true,
  });
};

export default uploadFileHelper;

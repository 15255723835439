<template>
  <div>
    <input
      type="file"
      ref="file"
      style="display: none"
      @change="onFileChange"
      :accept="accept"
    />
    <inqli-button @click="upload" :width="width">{{
      buttonTitle
    }}</inqli-button>
    <div class="error-msg">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import Button from "../buttons/Button.vue";
import { isValidImageFormat } from "../../utils/validation-helper";

export default {
  name: "UploadFileButton",
  components: {
    "inqli-button": Button,
  },
  data() {
    return {
      file: null,
      errorMessage: "",
      fileTypeMeta: {
        image: {
          accept: "image/*",
          validate: (file) => isValidImageFormat(file),
          errorMessage: `Only PNG, JPG and JPEG are accepted.`,
          title: "Upload image",
        },
      },
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      required: true,
    },
    fileType: {
      type: String,
      default: "image",
    },
    width: {
      type: String,
      default: "",
    },
  },
  computed: {
    buttonTitle() {
      return this.title || this.fileTypeMeta[this.fileType].title;
    },
    imageFile: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    accept() {
      return this.fileTypeMeta[this.fileType].accept;
    },
  },
  methods: {
    upload() {
      this.$refs.file.click();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (this.fileTypeMeta[this.fileType].validate(file)) {
        this.imageFile = file;
        this.$emit("change", file);
        this.errorMessage = "";
      } else {
        this.errorMessage = this.fileTypeMeta[this.fileType].errorMessage;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.error-msg {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
</style>

<template>
  <div>
    <v-select
      class="form-item"
      label="Country"
      :items="countryNameList"
      v-model="country"
      :error-messages="inputError"
    />
    <v-select
      label="State/Province"
      :items="stateNameList"
      v-model="state"
      class="form-item"
    />
    <v-select
      label="City"
      :items="cityNameList"
      v-model="city"
      class="form-item"
    />
  </div>
</template>
<script>
import {
  getCountryList,
  getStateListByCountry,
  getCityListByStateCountry,
} from "../../utils/location-helper";

export default {
  name: "LocationInput",
  props: {
    value: {
      type: Object,
      required: true,
    },
    inputError: {
      type: String,
      default: "",
    },
  },
  computed: {
    country: {
      get() {
        return this.value.country;
      },
      set(value) {
        this.$emit("input", {
          country: value,
          city: "",
          state: "",
        });
      },
    },
    state: {
      get() {
        return this.value.state;
      },
      set(value) {
        this.$emit("input", {
          country: this.country,
          state: value,
          city: "",
        });
      },
    },
    city: {
      get() {
        return this.value.city;
      },
      set(value) {
        this.$emit("input", {
          country: this.country,
          state: this.state,
          city: value,
        });
      },
    },
    countryNameList() {
      return getCountryList();
    },
    stateNameList() {
      const countryInput = this.country;
      return getStateListByCountry(countryInput);
    },
    cityNameList() {
      const stateInput = this.state;
      const countryInput = this.country;
      return getCityListByStateCountry(stateInput, countryInput);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item {
  margin: 0 20px;
  width: 30%;
}
</style>
